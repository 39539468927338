<template>
  <page-section gray>
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h2>{{ $t('view.requests.title') }}</h2>
      <por-btn variant="primary" class="align-self-end" :to="{ name: 'new-request' }">
        {{ $t('view.request.new.button') }}
      </por-btn>
    </div>
    <div class="d-flex justify-content-end mb-2">
      <div class="text-nowrap">
        <span>{{ $t('view.list.sort.title') }}</span>
        <link-selector
          class="position-relative d-inline-block py-1"
          v-model="sortOn"
          :options="sortOptions"
          data-test="sort"
        />
      </div>
    </div>
    <p v-if="!loading && sortedRequests && sortedRequests.length === 0">{{ $t('view.list.sort.message') }}</p>
    <loader v-if="loading" />

    <list-item
      v-for="request in sortedRequests"
      :key="request.id"
      :selected="
        $route.params.id === String(request.id) && $route.params.requestType === request.request.type.toLowerCase()
      "
      @select="$emit('selected', { id: String(request.id), type: request.request.type, ucrn: request.ucrn })"
      class="mb-3"
    >
      <div class="d-flex justify-content-between align-items-start" :data-test="[`request-${request.ucrn}`]">
        <div>
          <h6>
            <span class="sr-only">{{ $t('view.request.title') }} </span> {{ request.vessel.name }} ({{
              $t('generic.abbr.imo')
            }}
            {{ request.vessel.imo }}) - {{ typeLabel(request.request.type) }}
          </h6>
          <span class="d-inline-block"
            >{{ request.ucrn }} - {{ $t('view.request.creation.label') }}
            {{ formatDate(request.request.creationTime, 'DATETIME_SHORT') }}
            {{ request.request.applicant && $t('view.request.applicant', { applicant: request.request.applicant }) }}
          </span>
        </div>
        <b-badge pill :variant="request.request.evaluationStatus | evaluationStatusColour">
          {{ request.request.evaluationStatus | evaluationStatusLabel }}
        </b-badge>
      </div>
    </list-item>
  </page-section>
</template>

<script>
import { mapState } from 'vuex';
import { BBadge } from 'bootstrap-vue';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import kebabCase from 'lodash/kebabCase';
import words from 'lodash/words';
import every from 'lodash/every';
import filter from 'lodash/filter';
import { DateTime } from 'luxon';
import Loader from 'poronline-shared-vue-components/components/loader';
import PorBtn from 'poronline-shared-vue-components/components/por-btn';
import ListItem from 'poronline-shared-vue-components/components/list-item';
import LinkSelector from 'poronline-shared-vue-components/components/link-selector';
import withEchoLabels from '../../components/form/with-echo-form-labels';
import PageSection from '../../components/page/page-section';
import { evaluationStatusLabel } from '../../filters/evaluation-status';
import { evaluationStatusColour } from '../../filters/evaluation-status';

export default {
  name: 'RequestsListSection',
  filters: {
    evaluationStatusLabel,
    evaluationStatusColour,
  },
  mixins: [withEchoLabels],
  props: {
    filterText: { type: String },
  },
  components: { PageSection, PorBtn, ListItem, LinkSelector, Loader, BBadge },
  data() {
    const sortOptions = Object.freeze([
      {
        id: 'creationTimeSortDesc',
        value: [['creationTimeSort'], ['desc']],
        text: this.$t('view.list.sort.options.crea.desc'),
      },
      {
        id: 'creationTimeSortAsc',
        value: [['creationTimeSort'], ['asc']],
        text: this.$t('view.list.sort.options.crea.asc'),
      },
      {
        id: 'modificationTimeSortDesc',
        value: [['modificationTimeSort'], ['desc']],
        text: this.$t('view.list.sort.options.mod.desc'),
      },
      {
        id: 'modificationTimeSortAsc',
        value: [['modificationTimeSort'], ['asc']],
        text: this.$t('view.list.sort.options.mod.asc'),
      },
      {
        id: 'requestTypeSortDesc',
        value: [
          ['requestTypeSort', 'creationTimeSort'],
          ['desc', 'desc'],
        ],
        text: this.$t('view.list.sort.options.type.desc'),
      },
      {
        id: 'requestTypeSortAsc',
        value: [
          ['requestTypeSort', 'creationTimeSort'],
          ['asc', 'desc'],
        ],
        text: this.$t('view.list.sort.options.type.asc'),
      },
      {
        id: 'requestVesselNameSortDesc',
        value: [
          ['requestVesselNameSort', 'creationTimeSort'],
          ['desc', 'desc'],
        ],
        text: this.$t('view.list.sort.options.name.desc'),
      },
      {
        id: 'requestVesselNameSortAsc',
        value: [
          ['requestVesselNameSort', 'creationTimeSort'],
          ['asc', 'desc'],
        ],
        text: this.$t('view.list.sort.options.name.asc'),
      },
    ]);

    return {
      sortOptions,
      sortOn: sortOptions[0].value,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.form.requestsLoading,
      requests: (state) => state.form.requests,
    }),
    openRequests() {
      return !this.loading ? this.requests : [];
    },
    searchableSortableOpenRequests() {
      return this.openRequests.map((request) => {
        const requestTypeTranslation = this.$t(`view.request.type.${request.request.type}`);
        const requestStateTranslation = evaluationStatusLabel(request.request.evaluationStatus);
        return {
          ...request,
          vessel: request.request.vessel,
          creationTimeSort: DateTime.fromISO(request.request.creationTime).valueOf(),
          modificationTimeSort: DateTime.fromISO(request.request.modificationTime).valueOf(),
          requestTypeSort: requestTypeTranslation,
          requestVesselNameSort: request.request.vessel.name,
          searchableText: kebabCase(
            [
              request.ucrn,
              request.request.vessel.name,
              request.request.vessel.imo,
              requestTypeTranslation,
              requestStateTranslation,
            ].join(' ')
          ),
        };
      });
    },
    filterWords() {
      return this.filterText ? map(words(this.filterText), kebabCase) : null;
    },
    filteredOpenRequests() {
      if (!this.filterWords) {
        return this.searchableSortableOpenRequests;
      }

      return filter(this.searchableSortableOpenRequests, (request) =>
        every(this.filterWords, (word) => request.searchableText.includes(word))
      );
    },
    sortedRequests() {
      return orderBy(this.filteredOpenRequests, ...this.sortOn);
    },
  },
};
</script>
