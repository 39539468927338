<template>
  <page data-test="requests">
    <div slot="filters" class="w-100" data-test="requests-search">
      <div class="row">
        <input-search
          class="m-0 col-12 col-md-6 col-lg-4"
          v-model="searchModel.search"
          :placeholder="$t('view.list.search')"
        />
      </div>
    </div>
    <page-row>
      <page-column variant="6" gray scroll-container data-test="requests-list-section">
        <requests-list-section @selected="selectRequest" :filterText="filterText" />
      </page-column>
      <page-column variant="6" :gray="!requestDetails" scroll-container>
        <h5 class="text-center mt-5 py-4 text-secondary" v-if="!requestDetails">
          {{ $t('view.request.select') }}
        </h5>
        <request-details-section :request-details="combinedRequestListAndDetails" />
      </page-column>
    </page-row>
  </page>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import PageRow from 'poronline-shared-vue-components/components/page/row';
import PageColumn from 'poronline-shared-vue-components/components/page/column';
import InputSearch from 'poronline-shared-vue-components/components/form/input-search';
import RequestsListSection from './requests-list-section';
import RequestDetailsSection from './request-details-section';
import Page from '../../components/page/page';

export default {
  components: {
    Page,
    PageRow,
    PageColumn,
    InputSearch,
    RequestsListSection,
    RequestDetailsSection,
  },
  props: {
    id: String,
  },
  data() {
    return {
      searchModel: {},
      filterText: null,
    };
  },
  computed: {
    ...mapState({
      requestDetails: (state) => state.form.requestDetails,
    }),
    ...mapGetters({
      requestsByTypeId: 'REQUESTS_BY_TYPE_ID',
      visitsByUcrn: 'VISITS_LIST_BY_UCRN',
    }),
    combinedRequestListAndDetails() {
      return (
        this.requestDetails && {
          ...this.requestDetails,
          ...this.requestVessel,
        }
      );
    },
    requestVessel() {
      const { id, type, request } = this.requestDetails ?? {};
      const vessel = this.requestsByTypeId?.[type]?.[id]?.request?.vessel ?? this.visitsByUcrn?.[request?.ucrn]?.vessel;

      return { vessel };
    },
    searchText() {
      return this.searchModel.search;
    },
  },
  methods: {
    ...mapMutations({
      setRequestDetails: 'SET_REQUEST_DETAILS',
    }),
    ...mapActions({
      loadVisit: 'LOAD_VISIT',
      loadRequestDetails: 'LOAD_REQUEST_DETAILS',
    }),
    selectRequest({ id, type, ucrn }) {
      this.loadVisit(ucrn);

      const requestType = type.toLowerCase();
      const { id: routeId, requestType: routeRequestType } = this.$route.params;
      if (this.$route.name !== 'requests' || id !== routeId || requestType !== routeRequestType) {
        this.$router.push({ params: { id, requestType } });
      }
    },
  },
  watch: {
    id: {
      immediate: true,
      handler: async function (newId) {
        if (!newId) {
          this.setRequestDetails(null);
          return;
        }

        try {
          const id = Number.parseInt(newId, 10);
          const type = this.$route.params.requestType;
          await this.loadRequestDetails({ id, type });
        } catch (e) {
          this.$router.replace({ params: { id: null } });
        }
      },
    },
    searchText(searchValue) {
      if (searchValue && searchValue.length < 3) {
        return;
      }

      this.filterText = searchValue;
    },
  },
};
</script>
